import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_ca } from "../i18n/es-CA";

const FamigliaTessuti = (props) => {
  let lingua = {};

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "es-CA":
      lingua = lingua_ca;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "en-GB":
      lingua = lingua_en;
      break;
    default:
      lingua = lingua_it;
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3" style={{ marginBottom: "4rem" }}>
            {lingua.footer.famiglie}
          </h1>

          <h2 className="subtitle is-4">Ande</h2>
          <p>{lingua.sito.nostritessuti.ande}</p>
          <h2 className="subtitle is-4">Squire</h2>
          <p>{lingua.sito.nostritessuti.squire}</p>
          <h2 className="subtitle is-4">Piave</h2>
          <p>{lingua.sito.nostritessuti.piave}</p>
          <h2 className="subtitle is-4">Oxford 70</h2>
          <p>{lingua.sito.nostritessuti.oxford70}</p>
          <h2 className="subtitle is-4">Twill 70</h2>
          <p>{lingua.sito.nostritessuti.twill70}</p>
          <h2 className="subtitle is-4">Piquet 70</h2>
          <p>{lingua.sito.nostritessuti.piquet70}</p>
          <h2 className="subtitle is-4">Sahara Bright</h2>
          <p>{lingua.sito.nostritessuti.saharaBright}</p>
          <h2 className="subtitle is-4">St. James</h2>
          <p>{lingua.sito.nostritessuti.sJames}</p>
          <h2 className="subtitle is-4">Duke</h2>
          <p>{lingua.sito.nostritessuti.duke}</p>
          <h2 className="subtitle is-4">Piumino</h2>
          <p>{lingua.sito.nostritessuti.piumino}</p>
          <h2 className="subtitle is-4">Piave</h2>
          <p>{lingua.sito.nostritessuti.piave}</p>
          <h2 className="subtitle is-4">Bruges</h2>
          <p>{lingua.sito.nostritessuti.bruges}</p>
          <h2 className="subtitle is-4">Super Piumino</h2>
          <p>{lingua.sito.nostritessuti.superPiumino}</p>
          <h2 className="subtitle is-4">Silver</h2>
          <p>{lingua.sito.nostritessuti.silver}</p>
          <h2 className="subtitle is-4">Comfort</h2>
          <p>{lingua.sito.nostritessuti.comfort}</p>
          <h2 className="subtitle is-4">Portland</h2>
          <p>{lingua.sito.nostritessuti.portland}</p>
          <h2 className="subtitle is-4">Victoria Flam</h2>
          <p>{lingua.sito.nostritessuti.victoriaFlam}</p>
          <h2 className="subtitle is-4">Chambray</h2>
          <p>{lingua.sito.nostritessuti.chambray}</p>
          <h2 className="subtitle is-4">Buckingham</h2>
          <p>{lingua.sito.nostritessuti.buckingham}</p>
          <h2 className="subtitle is-4">Zephir</h2>
          <p>{lingua.sito.nostritessuti.zephir}</p>
          <h2 className="subtitle is-4">Royal Oxford</h2>
          <p>{lingua.sito.nostritessuti.royalOxford}</p>
          <h2 className="subtitle is-4">Journey</h2>
          <p>{lingua.sito.nostritessuti.journey}</p>
          <h2 className="subtitle is-4">Balmoral</h2>
          <p>{lingua.sito.nostritessuti.balmoral}</p>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(FamigliaTessuti);
